import Button from '@swe/shared/ui-kit/components/button';
import { useState } from 'react';

import { ApplicationPage } from '@swe/shop-ui/app/types';

const Err = () => {
  throw new Error('My tiny mount error');
};

const MountError: ApplicationPage = () => {
  const [mounted, setMounted] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setMounted(true)}
        ariaLabel="click"
      >
        Click to mount errored component
      </Button>
      {mounted && <Err />}
    </div>
  );
};

export default MountError;
